import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import config from '../../config';

class FooterTwo extends Component {
    render() {
        return (
            <footer className="footer-area bg-fffcf4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div className="single-footer-widget">
                                <div className="logo">
                                    <Link to="/">
                                        <img src={require('../../images/white-logo.png')} alt="" />
                                    </Link>
                                </div>
                                <ul className="contact-info">
                                    <li>En Chile desde 2009</li>
                                    <li>
                                        <Link to="/">www.neokode.cl</Link>
                                    </li>
                                    <li>
                                        <a href="mailto:contacto@neokode.cl">contacto@neokode.cl</a>
                                    </li>
                                    <li>
                                        <a href="https://api.whatsapp.com/send?phone=56935595008" target="_blanck">+56 9 3559 5008</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-8">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="single-footer-widget">
                                        <h3>Redes Sociales</h3>
                                        <ul className="list">
                                            <li>
                                                <a href="https://www.facebook.com/neokode" target="_blank" rel="noopener noreferrer">
                                                    <i className="fab fa-facebook"></i> Facebook
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/neokode.chile" target="_blank" rel="noopener noreferrer">
                                                    <i className="fab fa-instagram"></i> Instagram
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://twitter.com/neokodechile" target="_blank" rel="noopener noreferrer">
                                                    <i className="fab fa-twitter"></i> Twitter
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.linkedin.com/company/neokode" target="_blank" rel="noopener noreferrer">
                                                    <i className="fab fa-linkedin"></i> Linkedin
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6">
                                    <div className="single-footer-widget">
                                        <h3>Enlaces Rápidos</h3>

                                        <ul className="list">
                                            <li>
                                                <HashLink to="/#home">
                                                    Inicio
                                                </HashLink >
                                            </li>
                                            <li>
                                                <HashLink to="/#about">
                                                    Nosotros
                                                </HashLink>
                                            </li>
                                            <li>
                                                <HashLink to="/#saas">
                                                    Aplicaciones
                                                </HashLink>
                                            </li>
                                            <li>
                                                <Link to="/contact">
                                                    Hazte cliente
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/*<div className="col-lg-4 col-md-6">
                                <div className="single-footer-widget">
                                    <h3>Informaci&oacute;n</h3>

                                    <ul className="list">
                                        <li>
                                            <Link to="/privacy">
                                                Pol&iacute;tica de Privacidad
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/terms">
                                                T&eacute;rminos & Condiciones
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>*/}

                                <div className="col-lg-4 col-md-6">
                                    <div className="single-footer-widget">
                                        <h3>Mi Cuenta</h3>

                                        <ul className="list">
                                            <li>
                                                <a href={config.url.admin}>
                                                    Administración
                                                </a>
                                            </li>
                                            <li>
                                                <a href={config.url.support}>
                                                    Soporte
                                                </a>
                                            </li>
                                            <li>
                                                <Link to="/privacy">
                                                    Pol&iacute;tica de Privacidad
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/terms">
                                                    T&eacute;rminos & Condiciones
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copyright-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-md-7">
                                <p><i className="far fa-copyright"></i> 2025 <a href="/">Neokode <i className="far fa-registered"></i></a>. Todos los derechos reservados</p>
                            </div>
                            {/*<div className="col-lg-5 col-md-5">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/neokode" target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/neokodechile" target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/neokode.chile" target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/neokode" target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>*/}
                        </div>
                    </div>
                </div>

                <div className="map2">
                    <img src={require('../../images/map2.png')} alt="map" />
                </div>
            </footer>
        );
    }
}

export default FooterTwo;
